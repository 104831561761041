import { Alert } from "bootstrap";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import FulFilledOrder from "../Orders/FulfilledOrder";
import "./Dashboard.css";
import { ProgressBar } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import toast, { Toaster } from "react-hot-toast";
import Button from "react-bootstrap/Button";

const CMS_ENDPOINT =
  process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_CMS_ROUTE;

const DashboardPage = (props) => {
  const lang = props.Language;

  const [Dashboard, setDashboard] = useState({
    vendorData: 0,
    ordersData: 0,
    newOrderData: 0,
    newMessageData: 0,
    fulfilledData: 0,
    openOrderData: 0,
    awaitingReview: 0,
    preliminaryReview: 0,
    manufacturing: 0,
    qualityAssurance: 0,
    Packaging: 0,
    Shipping: 0,
    Flaged: 0,
  });

  const [loading, setloading] = useState(true);

  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  const spanClass = isActive
    ? "grv-card-order notifications-list active"
    : "grv-card-order notifications-list";

  async function fetchAPI(apiURL) {
    try {
      const response = await fetch(apiURL);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    } catch (error) {
      throw new Error(`API request failed: ${error.message}`);
    }
  }

  const fetchData = async () => {
    try {
      // const vendor = await fetchAPI(`${CMS_ENDPOINT}/vendors`);
      // setDashboard((presVal) => ({
      //   ...presVal,
      //   vendorData: vendor.data.length,
      //   loding: false,
      // }));
      const text = localStorage.getItem("tag");
      const defaultTag = localStorage.getItem("defaultTag");

      const id = localStorage.getItem("id");
      const email = localStorage.getItem("email");
      const lastLogin = localStorage.getItem("lastLogin");
      // const date = new Date(lastLogin * 1000);

      if (text !== null && defaultTag !== null && lastLogin !== null) {
        const fulfillOrders1 = await fetchAPI(
          `${CMS_ENDPOINT}/dashboardCount?email=${email}&id=${id}&lastLogin=${lastLogin}`
        );

        const {
          TotalFulfillmentCount,
          TotalOpenCount,
          newFlagCount,
          newOrderCount,
          MessageCount,
          TotalOrdersCount,
          vendorCount,
          OrderData,
        } = fulfillOrders1.data;

        const awaitingReview = OrderData.find(
          (e) => e.FulfillmentStatus === "awaiting-review"
        );
        const preliminaryReview = OrderData.find(
          (e) => e.FulfillmentStatus === "reviewed"
        );
        const manufacturing = OrderData.find(
          (e) => e.FulfillmentStatus === "manufactured"
        );
        const qualityAssurance = OrderData.find(
          (e) => e.FulfillmentStatus === "quality-assured"
        );
        const packaging = OrderData.find(
          (e) => e.FulfillmentStatus === "packaging"
        );
        const shippingCount = OrderData.find(
          (e) => e.FulfillmentStatus === "shipped"
        );

        setDashboard((presVal) => ({
          ...presVal,
          fulfilledData: TotalFulfillmentCount ?? 0,
          openOrderData: TotalOpenCount ?? 0,
          vendorData: vendorCount ?? 0,
          newOrderData: newOrderCount ?? 0,
          newMessageData: MessageCount ?? 0,
          awaitingReview: awaitingReview?.FulfillmentStatusCount ?? 0,
          preliminaryReview: preliminaryReview?.FulfillmentStatusCount ?? 0,
          manufacturing: manufacturing?.FulfillmentStatusCount ?? 0,
          qualityAssurance: qualityAssurance?.FulfillmentStatusCount ?? 0,
          Packaging: packaging?.FulfillmentStatusCount ?? 0,
          Shipping: shippingCount?.FulfillmentStatusCount ?? 0,
          Flaged: newFlagCount ?? 0,
        }));

        // if(MessageCount>0){
        // toast.success(`${MessageCount} New Message `)
        // }

        setloading(false);
      } else {
        localStorage.clear();
      }
    } catch (error) {
      setloading(false);
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const navigate = useNavigate();

  const TotalOrders = Dashboard.fulfilledData + Dashboard.openOrderData;

  const AwaitingProgress = (Dashboard.awaitingReview / TotalOrders) * 100;
  const PreliminaryProgress = (Dashboard.preliminaryReview / TotalOrders) * 100;
  const ManufacturingProgress = (Dashboard.manufacturing / TotalOrders) * 100;
  const QualityAssuranceProgress =
    (Dashboard.qualityAssurance / TotalOrders) * 100;
  const PackagingProgress = (Dashboard.Packaging / TotalOrders) * 100;
  const ShippingProgress = (Dashboard.Shipping / TotalOrders) * 100;
  const FulfillmentProgress = (Dashboard.fulfilledData / TotalOrders) * 100;
  return (
    <>
      <div className="container-fluid">
        {/* <div className="ForAndBak"> */}
        {/* <div className="BackButton" onClick={() => navigate(-1)}><i className="fas fa-arrow-left backbtn"></i></div> */}
        {/* <button className="BackButton" onClick={() => navigate(-1)}>
            Back
          </button> */}
        {/* <div class="back-button">
          <a href="#" class="label">
            back
          </a>
          </div> */}
        {/* </div> */}
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800 HeadTitles">
            {lang === "en" ? "Dashboard" : "仪表盘"}
          </h1>
        </div>

        {/* <div className="row">
          {loading ? (
            <FlagedOrdersDash />
          ) : (
            <Link
              className="col-xl-3 col-md-5 mb-10 p-2 m-2"
              to="/orders/flagged"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              <div>
                <div className="card border-left-danger shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="font-weight-bold text-danger text-uppercase mb-1">
                          Flagged Orders
                        </div>

                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                          {Dashboard.Flaged}
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-flag fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          )}

          {loading ? (
            <NewOrdersDash />
          ) : (
            <Link
              className="col-xl-3 col-md-5 mb-10 p-2 m-2"
              to="#"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              <div>
                <div className="card border-left-success shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="font-weight-bold text-success text-uppercase mb-1">
                          New Orders Since Last Login
                        </div>

                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                          {Dashboard.newOrderData}
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-list-ol fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          )}

          {loading ? (
            <NewMessagesDash />
          ) : (
            <Link
              className="col-xl-3 col-md-5 mb-10 p-2 m-2"
              to="/orders/message"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              <div>
                <div className="card border-left-warning shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="font-weight-bold text-warning text-uppercase mb-1">
                          New Message
                        </div>

                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                          {Dashboard.newMessageData}
                        </div>
                      </div>
                      <div className="col-auto">
                        
                        <i className="fas fa-comments fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          )}
        </div> */}
        {/* <div className="row">
          {loading ? (
            <TotalOrdersDashCard />     
          ) : (
            <Link
              className="col-xl-3 col-md-5 mb-10 p-2 m-2"
              to="/orders"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              <div>
                <div className="card border-left-primary shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="font-weight-bold text-primary text-uppercase mb-1">
                          Total Orders
                        </div>

                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                         
                          {Dashboard.fulfilledData + Dashboard.openOrderData}
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-calendar fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          )}

          {localStorage?.getItem("tag") === "admin" ? (
            loading ? (
              <VendorsSkle />
            ) : (
              <Link
                className="col-xl-3 col-md-5 mb-10 p-2 m-2"
                to="/vendor"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                <div>
             
                  <div className="card border-left-info shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                        
                          <div className="font-weight-bold text-info text-uppercase mb-1">
                            Vendors
                          </div>

                          <div className="row no-gutters align-items-center">
                            <div className="col-auto">
                              <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                                {Dashboard.vendorData}
                              </div>
                            </div>
                            
                          </div>
                        </div>
                        <div className="col-auto">
                          
                          <i className="fas fa-store fa-2x text-gray-300"></i>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            )
          ) : (
            ""
          )}

          {loading ? (
            <FulfilledSkle />
          ) : (
            <Link
              className="col-xl-3 col-md-5 mb-10 p-2 m-2"
              to="/orders/fulfillment"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              <div>
                <div className="card border-left-dark shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="font-weight-bold text-dark text-uppercase mb-1">
                          Fulfilled
                        </div>

                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                          {Dashboard.fulfilledData}
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-check-double fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </Link>
            )}

          {loading ? (
              <OpneOrdersSkle />
            ) : (
          <Link
            className="col-xl-3 col-md-5 mb-10 p-2 m-2"
            to="/orders/open"
            data-toggle="collapse"
            data-target="#collapseTwo"
            aria-expanded="true"
            aria-controls="collapseTwo"
          >
              <div>
                <div className="card border-left-secondary shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="font-weight-bold text-secondary text-uppercase mb-1">
                          Open orders
                        </div>

                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                          {Dashboard.openOrderData}
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-warehouse fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          )}
        </div> */}

        <div className="row">
          {/* {loading ? ( */}
          {/* <NotificationsSkle /> */}
          {/* ) : ( */}
          <div className="col-xl-6 col-md-6 mb-10 p-2 m-2 NotificationCSSRes ">
            <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className=" font-weight-bold text-primary text-uppercase mb-1">
                      {loading ? (
                        <Skeleton height={20} width={120} />
                      ) : (
                        <>
                          {/* <p>Notification Center</p> */}
                          <p>
                            {lang === "en" ? "Notification Center" : "通知中心"}
                          </p>
                        </>
                      )}
                    </div>

                    <div className="h6 mb-0 font-weight-bold text-gray-800">
                      <ul className="grv-card-order customcss">
                        <li className="py-3">
                          <Link to="/orders/flagged" className="grv-order-left">
                            <span>
                              {loading ? (
                                <Skeleton height={30} width={30} />
                              ) : (
                                <i className="fas fa-flag fa-2x text-danger"></i>
                              )}
                            </span>
                            <span className="NotificationsCardMain grv-order-type pl-3 text-danger">
                              {loading ? (
                                <Skeleton height={20} width={160} />
                              ) : (
                                <p>{lang === "en" ? "New Flags" : "新标记"}</p>
                              )}
                            </span>
                          </Link>
                          {loading ? (
                            <Skeleton height={30} width={30} />
                          ) : (
                            <p className="grv-order-count">
                              {Dashboard.Flaged}
                            </p>
                          )}
                        </li>
                        <li className="py-3">
                          <Link to="/orders/message" className="grv-order-left">
                            <span>
                              {loading ? (
                                <Skeleton height={30} width={30} />
                              ) : (
                                <i className="fas fa-comments fa-2x text-warning"></i>
                              )}
                            </span>
                            <span className=" NotificationsCardMain grv-order-type pl-3 text-warning">
                              {loading ? (
                                <Skeleton height={20} width={160} />
                              ) : (
                                <p>
                                  {lang === "en" ? "New Messages" : "新信息"}
                                </p>
                              )}
                            </span>
                          </Link>
                          {loading ? (
                            <Skeleton height={30} width={30} />
                          ) : (
                            <p className="grv-order-count">
                              {Dashboard.newMessageData}
                            </p>
                          )}
                        </li>
                        <li className="py-3">
                          <Link to="/orders" className="grv-order-left">
                            <span>
                              {loading ? (
                                <Skeleton height={30} width={30} />
                              ) : (
                                <i className="fas fa-bell fa-2x text-success"></i>
                              )}

                              {/* <i className="fas fa-list-ol fa-2x text-danger-300"></i> */}
                            </span>
                            <span className=" NotificationsCardMain grv-order-type pl-3 text-success">
                              {loading ? (
                                <Skeleton height={20} width={160} />
                              ) : (
                                <>
                                  <p>
                                    {lang === "en" ? "New Orders" : "新订单"}
                                  </p>
                                  <span>
                                    {lang === "en"
                                      ? "(Since Last Login)"
                                      : "（自上次登陆）"}
                                  </span>
                                </>
                              )}
                            </span>
                          </Link>
                          {loading ? (
                            <Skeleton height={30} width={30} />
                          ) : (
                            <p className="grv-order-count">
                              {Dashboard.newOrderData}
                            </p>
                          )}
                        </li>

                        <li className="py-3" style={{ borderBottom: "none" }}>
                          <Link to="/orders/open" className="grv-order-left">
                            <span>
                              {loading ? (
                                <Skeleton height={30} width={30} />
                              ) : (
                                <i className="fas fa-bell fa-2x text-primary"></i>
                              )}

                              {/* <i className="fas fa-list-ol fa-2x text-danger-300"></i> */}
                            </span>
                            <span className=" NotificationsCardMain grv-order-type pl-3 text-primary">
                              {loading ? (
                                <Skeleton height={20} width={160} />
                              ) : (
                                <>
                                  <p>
                                    {lang === "en"
                                      ? "Open Orders"
                                      : "进行中的订单"}
                                  </p>
                                </>
                              )}
                            </span>
                          </Link>
                          {loading ? (
                            <Skeleton height={30} width={30} />
                          ) : (
                            <p className="grv-order-count">
                              {Dashboard.openOrderData}
                            </p>
                          )}
                        </li>

                        {/* {localStorage?.getItem("tag") === "admin" ? (
                          <li className="py-3">
                            <Link to="/vendor" className="grv-order-left">
                              <span>
                                {loading ? (
                                  <Skeleton height={30} width={30} />
                                ) : (
                                  <i className="fas fa-store fa-2x text-info"></i>
                                )}
                              </span>
                              <span className=" NotificationsCardMain grv-order-type pl-3 text-info">
                                {loading ? (
                                  <Skeleton height={20} width={160} />
                                ) : (
                                  <p>Vendors</p>
                                )}
                              </span>
                            </Link>
                            {loading ? (
                              <Skeleton height={30} width={30} />
                            ) : (
                              <p className="grv-order-count">
                                {Dashboard.vendorData}
                              </p>
                            )}
                          </li>
                        ) : (
                          ""
                        )} */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* )} */}
        </div>
        <div className="row">
          <div className="col-xl-6 col-md-6 mb-10 p-2 m-2 NotificationCSSRes">
            <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div
                      className=" font-weight-bold text-primary text-uppercase mb-1"
                      style={{ cursor: "pointer" }}
                      onClick={handleClick}
                    >
                      {loading ? (
                        <Skeleton height={20} width={100} />
                      ) : (
                        <>
                          <p>{lang === "en" ? "Order Count" : "订单数量"}</p>
                          <span className="grv-plus">+</span>
                        </>
                      )}
                    </div>

                    <div className="h6 mb-0 font-weight-bold text-gray-800">
                      <ul className={spanClass}>
                        <li className="py-1">
                          <Link
                            to="/orders/awaiting-review"
                            className="grv-order-left"
                          >
                            <span className="grv-order-circle bg-warning">
                              {loading ? (
                                <Skeleton height={30} width={30} />
                              ) : (
                                <p>A</p>
                              )}
                            </span>
                            <span
                              className="grv-order-type pl-3"
                              style={{ width: 137 }}
                            >
                              {loading ? (
                                <Skeleton height={20} width={100} />
                              ) : (
                                <p>
                                  {lang === "en"
                                    ? "Awaiting Review"
                                    : "等待审核"}
                                </p>
                              )}
                            </span>
                          </Link>
                          {loading ? (
                            <Skeleton
                              className="ProgressSck"
                              height={20}
                              width={150}
                            />
                          ) : (
                            <ProgressBar
                              className="DashProgressBar"
                              variant="warning"
                              now={AwaitingProgress}
                            />
                          )}
                          {loading ? (
                            <Skeleton height={20} width={20} />
                          ) : (
                            <p className="grv-order-count">
                              {Dashboard.awaitingReview}
                            </p>
                          )}
                        </li>
                        <li className="py-1">
                          <Link
                            to="/orders/reviewed"
                            className="grv-order-left"
                          >
                            <span className="grv-order-circle bg-primary">
                              {loading ? (
                                <Skeleton height={30} width={30} />
                              ) : (
                                <p>R</p>
                              )}
                            </span>
                            <span
                              className="grv-order-type pl-3"
                              style={{ width: 137 }}
                            >
                              {loading ? (
                                <Skeleton height={20} width={100} />
                              ) : (
                                <p>{lang === "en" ? "Reviewed" : "已审核"}</p>
                              )}
                            </span>
                          </Link>
                          {loading ? (
                            <Skeleton
                              className="ProgressSck"
                              height={20}
                              width={150}
                            />
                          ) : (
                            <ProgressBar
                              className="DashProgressBar"
                              variant="primary"
                              now={PreliminaryProgress}
                            />
                          )}

                          {loading ? (
                            <Skeleton height={20} width={20} />
                          ) : (
                            <p className="grv-order-count">
                              {Dashboard.preliminaryReview}
                            </p>
                          )}
                        </li>
                        <li className="py-1">
                          <Link
                            to="/orders/manufactured"
                            className="grv-order-left"
                          >
                            <span className="grv-order-circle bg-info">
                              {loading ? (
                                <Skeleton height={30} width={30} />
                              ) : (
                                <p>M</p>
                              )}
                            </span>
                            <span
                              className="grv-order-type pl-3"
                              style={{ width: 137 }}
                            >
                              {loading ? (
                                <Skeleton height={20} width={100} />
                              ) : (
                                <p>
                                  {lang === "en" ? "Manufactured" : "已生产"}
                                </p>
                              )}
                            </span>
                          </Link>
                          {loading ? (
                            <Skeleton
                              className="ProgressSck"
                              height={20}
                              width={150}
                            />
                          ) : (
                            <ProgressBar
                              className="DashProgressBar"
                              variant="info"
                              now={ManufacturingProgress}
                            />
                          )}
                          {loading ? (
                            <Skeleton height={20} width={20} />
                          ) : (
                            <p className="grv-order-count">
                              {Dashboard.manufacturing}
                            </p>
                          )}
                        </li>
                        {/* <li className="py-1">
                          <Link
                            to="/orders/quality-assured"
                            className="grv-order-left"
                          >
                            <span className="grv-order-circle bg-danger">
                              {loading ? (
                                <Skeleton height={30} width={30} />
                              ) : (
                                <p>Q</p>
                              )}
                            </span>
                            <span
                              className="grv-order-type pl-3"
                              style={{ width: 137 }}
                            >
                              {loading ? (
                                <Skeleton height={20} width={100} />
                              ) : (
                                <p>{lang==="en" ? "Quality Assured":"质量已把关"}</p>
                              )}
                            </span>
                          </Link>
                          {loading ? (
                            <Skeleton
                              className="ProgressSck"
                              height={20}
                              width={150}
                            />
                          ) : (
                            <ProgressBar
                              className="DashProgressBar"
                              variant="danger"
                              now={QualityAssuranceProgress}
                            />
                          )}

                          {loading ? (
                            <Skeleton height={20} width={20} />
                          ) : (
                            <p className="grv-order-count">
                              {Dashboard.qualityAssurance}
                            </p>
                          )}
                        </li>
                        <li className="py-1">
                          <Link
                            to="/orders/packaging"
                            className="grv-order-left"
                          >
                            <span className="grv-order-circle bg-success">
                              {loading ? (
                                <Skeleton height={30} width={30} />
                              ) : (
                                <p>P</p>
                              )}
                            </span>
                            <span
                              className="grv-order-type pl-3"
                              style={{ width: 137 }}
                            >
                              {loading ? (
                                <Skeleton height={20} width={100} />
                              ) : (
                                <p>{lang==="en" ? "Packaging":"包装"}</p>
                              )}
                            </span>
                          </Link>
                          {loading ? (
                            <Skeleton
                              className="ProgressSck"
                              height={20}
                              width={150}
                            />
                          ) : (
                            <ProgressBar
                              className="DashProgressBar"
                              variant="success"
                              now={PackagingProgress}
                            />
                          )}

                          {loading ? (
                            <Skeleton height={20} width={20} />
                          ) : (
                            <p className="grv-order-count">
                              {Dashboard.Packaging}
                            </p>
                          )}
                        </li> */}
                        <li className="py-1">
                          <Link to="/orders/shipped" className="grv-order-left">
                            <span className="grv-order-circle bg-secondary">
                              {loading ? (
                                <Skeleton height={30} width={30} />
                              ) : (
                                <p>S</p>
                              )}
                            </span>
                            <span
                              className="grv-order-type pl-3"
                              style={{ width: 137 }}
                            >
                              {loading ? (
                                <Skeleton height={20} width={100} />
                              ) : (
                                <p>{lang === "en" ? "Shipped" : "已发货"}</p>
                              )}
                            </span>
                          </Link>
                          {loading ? (
                            <Skeleton
                              className="ProgressSck"
                              height={20}
                              width={150}
                            />
                          ) : (
                            <ProgressBar
                              className="DashProgressBar"
                              variant="secondary"
                              now={ShippingProgress}
                            />
                          )}

                          {loading ? (
                            <Skeleton height={20} width={20} />
                          ) : (
                            <p className="grv-order-count">
                              {Dashboard.Shipping}
                            </p>
                          )}
                        </li>
                        <li className="py-1" style={{ borderBottom: "none" }}>
                          <Link
                            to="/orders/fulfilled"
                            className="grv-order-left"
                          >
                            <span className="grv-order-circle bg-dark">
                              {loading ? (
                                <Skeleton height={30} width={30} />
                              ) : (
                                <p>F</p>
                              )}
                            </span>
                            <span
                              className="grv-order-type pl-3"
                              style={{ width: 137 }}
                            >
                              {loading ? (
                                <Skeleton height={20} width={100} />
                              ) : (
                                <p>{lang === "en" ? "Fulfilled" : "實現了"}</p>
                              )}
                            </span>
                          </Link>
                          {loading ? (
                            <Skeleton
                              className="ProgressSck"
                              height={20}
                              width={150}
                            />
                          ) : (
                            <ProgressBar
                              className="DashProgressBar"
                              variant="dark"
                              now={FulfillmentProgress}
                            />
                          )}

                          {loading ? (
                            <Skeleton height={20} width={20} />
                          ) : (
                            <p className="grv-order-count">
                              {Dashboard.fulfilledData}
                            </p>
                          )}
                        </li>
                        {/* <li className="py-1">
                              <Link
                                to="/orders/awaiting-review"
                                className="grv-order-left"
                              >
                                <span className="grv-order-circle">U</span>
                                <span className="grv-order-type pl-3">
                                  Unfulfilled
                                </span>
                              </Link>
                              <p className="grv-order-count">
                                {Dashboard.openOrderData}
                              </p>
                            </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* )} */}
        </div>
      </div>
      <Toaster />
    </>
  );
};

export default DashboardPage;
