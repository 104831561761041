import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import LoginProtected from "./LoginProtected.js";
import Protected from "./Protected.js";
import Orders from "../Pages/Orders/Orders";
import FulFilledOrder from "../Pages/Orders/FulfilledOrder";
import OpenOrder from "../Pages/Orders/OpenOrder.js";
import DashboardPage from "../Pages/Dashboard/Dashboard";
import OrderDetailsPage from "../Pages/OrderDetails";
import PageNotFound from "./PageNotFound";
import Login from "../Pages/Login";
import Register from "../Pages/Register";
import ForgotPassword from "../Pages/Forgotpassword";
import Sidebar from "./Sidebar/Sidebar";
import Topbar from "./Topbar/Topbar";
import Profile from "../Pages/Profile";
import { useEffect, useState } from "react";
import Vendor from "../Pages/Vendor.js";
import Enterprise from "../Pages/Enterprise.js";
import EnterpriseDetails from "../Pages/Enterprise/EnterpriseDetails.jsx";
import { getCookieValue } from "../Helpers/Auth.js";
import SLAReports from "../Pages/SLAReports/SLAReports.js";

const DashboardRoutes = () => {
  const [data, setData] = useState(false);
  const [lang, setLang] = useState("en");
  const location = useLocation();
  // let loggedInUser = localStorage.getItem("LoggedIn");
  let loggedInUser = getCookieValue("loginCookies");

  const tag = localStorage.getItem("tag");

  const handleChildData = (data) => {
    setData(data);
    // console.log("Data received from child:", data);
    // Process the data in the parent component
  };
  const handlelang = (data) => {
    setLang(data);
  };

  const navigate = useNavigate();
  return (
    <>
      <div id="wrapper">
        {location.pathname == "/" || loggedInUser == null ? (
          ""
        ) : (
          <Sidebar
            message={data}
            onDataUpdate={handleChildData}
            Language={lang}
          />
        )}
        <div id="content-wrapper" className=" d-flex flex-column">
          {location.pathname == "/" || loggedInUser == null ? (
            ""
          ) : (
            <Topbar
              message={data}
              Language={lang}
              onLangUpdate={handlelang}
              onDataUpdate={handleChildData}
            />
          )}

          <Routes>
            {loggedInUser ? (
              <Route
                path="/"
                element={
                  <LoginProtected>
                    <Login Language={lang} />
                  </LoginProtected>
                }
              />
            ) : (
              <Route path="*" element={<Login />} />
            )}
            {loggedInUser ? (
              <Route
                path="/register"
                element={
                  <LoginProtected>
                    <Register Language={lang} />
                  </LoginProtected>
                }
              />
            ) : (
              <Route path="*" element={<Login />} />
            )}
            {loggedInUser ? (
              <Route
                path="/forgotpassword"
                element={
                  <LoginProtected>
                    <ForgotPassword Language={lang} />
                  </LoginProtected>
                }
              />
            ) : (
              <Route path="*" element={<Login />} />
            )}
            {loggedInUser ? (
              <Route
                path="/orders"
                element={
                  <Protected>
                    <Orders Language={lang} />
                  </Protected>
                }
              />
            ) : (
              <Route path="*" element={<Login />} />
            )}
            {loggedInUser ? (
              <Route
                path="/order/:id"
                element={
                  <Protected>
                    <OrderDetailsPage Language={lang} />
                  </Protected>
                }
              />
            ) : (
              <Route path="*" element={<Login />} />
            )}
            {loggedInUser ? (
              <Route
                path="/dashboard"
                element={
                  <Protected>
                    <DashboardPage Language={lang} />
                  </Protected>
                }
              />
            ) : (
              <Route path="*" element={<Login />} />
            )}
            {loggedInUser ? (
              <Route
                path="/profile"
                element={
                  <Protected>
                    <Profile Language={lang} />
                  </Protected>
                }
              />
            ) : (
              <Route path="*" element={<Login />} />
            )}
            {loggedInUser ? (
              <Route
                path="/sla-reports"
                element={
                  <Protected>
                    <SLAReports Language={lang} />
                  </Protected>
                }
              />
            ) : (
              <Route path="*" element={<Login />} />
            )}
            {loggedInUser ? (
              <Route
                path="/orders/:status"
                element={
                  <Protected>
                    <FulFilledOrder Language={lang} />
                  </Protected>
                }
              />
            ) : (
              <Route path="*" element={<Login />} />
            )}
            {/* <Route path="/orders/open" element={<Protected><OpenOrder/></Protected>}/> */}

            {tag === "admin" && (
              <Route
                path="/vendor"
                element={
                  <Protected>
                    <Vendor Language={lang} />
                  </Protected>
                }
              />
            )}
            {tag === "admin" && (
              <Route
                path="/enterprise"
                element={
                  <Protected>
                    <Enterprise Language={lang} />
                  </Protected>
                }
              />
            )}
            {tag === "admin" && (
              <Route
                path="/enterprise/:id"
                element={
                  <Protected>
                    <EnterpriseDetails Language={lang} />
                  </Protected>
                }
              />
            )}
            {loggedInUser ? (
              <Route path="*" element={<PageNotFound Language={lang} />} />
            ) : (
              <Route path="*" element={<Login />} />
            )}
          </Routes>
        </div>
      </div>
    </>
  );
};

export default DashboardRoutes;
