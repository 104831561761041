import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "./SLAReports.css";
import axios from "axios";

const CMS_ENDPOINT =
  process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_CMS_ROUTE;

const SLAReports = (props) => {
  const lang = props.Language;
  const [startDate, setStartDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 1))
      .toISOString()
      .split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const [slaData, setSLAData] = useState({
    message: "",
    startDate: "",
    endDate: "",
    fromStartStatusAverages: [],
    consecutiveStatusAverages: [],
  });
  const [totalItems, setTotalItems] = useState(0);
  const [avgResolutionTime, setAvgResolutionTime] = useState("0.00");
  const [loading, setLoading] = useState(false);

  const getSLAData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${CMS_ENDPOINT}/sla?startDate=${startDate}&endDate=${endDate}`
      );
      setSLAData(response.data);

      const totalProcessed = response.data.fromStartStatusAverages.reduce(
        (sum, item) => sum + item.count,
        0
      );
      setTotalItems(totalProcessed || 0);

      const shippedTransition = response.data.fromStartStatusAverages.find(
        (item) => item.statusPair === "awaiting-review_to_shipped"
      );
      setAvgResolutionTime(shippedTransition?.averageHours || "0.00");
    } catch (error) {
      console.error("Error fetching SLA data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      getSLAData();
    }
  }, [startDate, endDate]);

  // Helper function to format status pair for display
  const formatStatusPair = (statusPair) => {
    if (!statusPair) return "";

    if (statusPair.includes("_to_")) {
      const targetStatus = statusPair.split("_to_")[1];
      return targetStatus.charAt(0).toUpperCase() + targetStatus.slice(1);
    }

    return statusPair
      .replace("_to_", " - ")
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("-");
  };

  // Helper function to get badge class based on hours
  const getBadgeClass = (hours) => {
    const numHours = parseFloat(hours);
    if (numHours <= 3) return "fast";
    if (numHours <= 5) return "moderate";
    return "slow";
  };

  // const getCategoryTitle = (statusPair) => {
  //   if (!statusPair) return "";

  //   const source = statusPair.split("_to_")[0];
  //   return source
  //     .split("-")
  //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //     .join(" ");
  // };

  const getFromStartData = () => {
    return slaData.fromStartStatusAverages || [];
  };

  const getConsecutiveStatusData = () => {
    if (!slaData.consecutiveStatusAverages) return {};

    const grouped = {};

    slaData.consecutiveStatusAverages.forEach((item) => {
      if (!item.statusPair) return;

      const [from] = item.statusPair.split("_to_");
      if (!from) return;

      const category = from
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

      if (!grouped[category]) {
        grouped[category] = [];
      }

      grouped[category].push(item);
    });

    return grouped;
  };

  const consecutiveGroups = getConsecutiveStatusData();

  return (
    <Container fluid className="p-4">
      <div className="sla-dashboard">
        <h2>{lang === "en" ? "SLA Reporting Dashboard" : "SLA 报告仪表板"}</h2>
        <div className="filters">
          <label htmlFor="from-date">{lang === "en" ? "From:" : "从："}</label>
          <input
            type="date"
            id="from-date"
            placeholder="dd/mm/yyyy"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            max={new Date().toISOString().split("T")[0]}
          />
          <label htmlFor="to-date">{lang === "en" ? "To:" : "到："}</label>
          <input
            type="date"
            id="to-date"
            placeholder="dd/mm/yyyy"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            max={new Date().toISOString().split("T")[0]}
          />
        </div>
        <div className="summary">
          <div className="summary-card">
            <span className="summary-value" id="total-orders">
              {totalItems}
            </span>
            <h4>{lang === "en" ? "Total Orders Placed" : "总订单数"}</h4>
          </div>
          <div className="summary-card">
            <span className="summary-value" id="avg-resolution">
              {avgResolutionTime} {lang === "en" ? "Hours" : "小时"}
            </span>
            <h4>{lang === "en" ? "Avg. Resolution Time" : "平均解决时间"}</h4>
          </div>
        </div>
        <table className="report-table">
          <thead>
            <tr>
              <th>{lang === "en" ? "Process Stage" : "处理阶段"}</th>
              <th>{lang === "en" ? "Number of Orders" : "订单数量"}</th>
              <th>{lang === "en" ? "Average Time Taken" : "平均耗时"}</th>
            </tr>
          </thead>
          <tbody>
            {/* From Start Status Section */}
            <tr className="category-header">
              <td colSpan="3">
                {lang === "en" ? "From Order Placement" : "从订单下达开始"}
              </td>
            </tr>
            {getFromStartData().length > 0 ? (
              getFromStartData().map((item, index) => (
                <tr key={`from-start-${index}`}>
                  <td>
                    {lang === "en" ? "To " : "到"}
                    {formatStatusPair(item.statusPair)}
                  </td>
                  <td>{item.count}</td>
                  <td>
                    <span
                      className={`badge ${getBadgeClass(item.averageHours)}`}
                    >
                      {item.averageHours} {lang === "en" ? "Hours" : "小时"}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="no-data">
                  {lang === "en" ? "No data available" : "无可用数据"}
                </td>
              </tr>
            )}

            {/* Consecutive Status Sections */}
            {Object.keys(consecutiveGroups).length > 0 ? (
              Object.entries(consecutiveGroups).map(
                ([category, items], categoryIndex) => (
                  <React.Fragment key={`category-${categoryIndex}`}>
                    <tr className="category-header">
                      <td colSpan="3">
                        {lang === "en" ? "From " : "从"}
                        {category}
                      </td>
                    </tr>
                    {items.map((item, itemIndex) => (
                      <tr key={`consecutive-${categoryIndex}-${itemIndex}`}>
                        <td>
                          {lang === "en" ? "To " : "到"}
                          {formatStatusPair(item.statusPair)}
                        </td>
                        <td>{item.count}</td>
                        <td>
                          <span
                            className={`badge ${getBadgeClass(
                              item.averageHours
                            )}`}
                          >
                            {item.averageHours}{" "}
                            {lang === "en" ? "Hours" : "小时"}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                )
              )
            ) : (
              <>
                <tr className="category-header">
                  <td colSpan="3">
                    {lang === "en" ? "From Reviewed" : "从审核开始"}
                  </td>
                </tr>
                <tr>
                  <td colSpan="3" className="no-data">
                    {lang === "en" ? "No data available" : "无可用数据"}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>

        {loading && (
          <div className="loading">
            {lang === "en" ? "Loading data..." : "正在加载数据..."}
          </div>
        )}
      </div>
    </Container>
  );
};

export default SLAReports;
